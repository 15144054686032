import { getFeedbackRecInSession, setFeedbackRecInSession } from './utils'

export default {
  mounted() {
    this.hideFeedbackRecPopup()
    this.clearUseGooIds()
  },
  beforeDestory() {
    this.clearUseGooIds()
  },
  data() {
    return {
      useGoodId: [] // 页面不刷新，已经使用过的goodId
    }
  },
  methods: {
    // 在路由变化，页面刷新时外层触发调用，清空
    clearUseGooIds() {
      this.useGoodId = []
      const { path } = this.$route || {}
      const key = `show_search_rec_times_${path}`
      sessionStorage.setItem(key, 0)
    },
    getProductListItemInstance(index) {
      const productListItemRef = this.$refs?.[`productListItem_${index}`]
      return productListItemRef?.[0] || productListItemRef
    },
    // 通过商品的 goodsId 找到对应商品在 goods 列表中的 index
    // 注意: 入参的 goodsId 有可能是色块的 goodsId
    getCurItemIndex(goodsId) {
      // 修复，因为插坑优化导致的影响,参见：BUG单： CTD-8404
      if(this.waterItems) {
        return this.waterItems?.findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
      }
      return this.goods?.findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
    },
    // 更新数据
    updatePopData() {
      const { goodsId } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex < 0) return
      
      // 再通过 index 拿到对应的 productListItem 实例
      const listItemRef = this.getProductListItemInstance(goodsIndex)
      if (!listItemRef) return
      let recommendPopupRef = listItemRef?.$refs?.CardRecommend
      recommendPopupRef?.updateData() // 手动筛选推荐数据
    },
    // 显示反馈弹窗
    async showFeedbackRecPopup() {
      const { goodsId, addBagStatus, addWishStatus } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex < 0) return
      
      // 再通过 index 拿到对应的 productListItem 实例
      const listItemRef = this.getProductListItemInstance(goodsIndex)
      if (!listItemRef) return
      let recommendPopupRef = listItemRef?.$refs?.CardRecommend
      if (!recommendPopupRef) return

      const refNameTable = {
        'cate_feedback': 'recommendCatePop',
        'hotRanking_feedback': 'recommendRankPopup',
        'search_feedback': 'recommendSearchSlider',
        'realtime_feedback': 'recommendPopup',
        'itemSearch_feedback': 'recommendItemSearchPopup',
        // 'test': 'searchWordAllCard',
        // 'test22': 'searchWordAllCard'
      }
      // 处理搜索词推荐，比较特殊化
      if(recommendPopupRef?.showNewRecPopType === 'search_feedback' && (this.useGoodId?.includes(goodsId) || this.useGoodId?.includes(goodsId + '') || this.useGoodId?.includes(+goodsId))) {
        // 如果已经打开过则不在展示，兼容商详页和加车弹层 存储的goodsId类型值不一样
        return
      }

      recommendPopupRef.showCardRecommend = true

      // 等待 recommendPopup 渲染出来
      await this.$nextTick()
      // recommendPopupRef = listItemRef?.$refs?.recommendPopup || listItemRef?.$refs?.recommendCatePop
      // recommendPopupRef && !recommendPopupRef.show && recommendPopupRef.setFeedbackRecInfo({ goodsId, addBagStatus, addWishStatus })
      if(recommendPopupRef?.$refs[refNameTable[recommendPopupRef.showNewRecPopType]] && !recommendPopupRef?.$refs[refNameTable[recommendPopupRef.showNewRecPopType]]?.show) {
        recommendPopupRef.$refs[refNameTable[recommendPopupRef.showNewRecPopType]]?.setFeedbackRecInfo({ goodsId, addBagStatus, addWishStatus })
        this.useGoodId.push(goodsId)
      }
    },
    // 隐藏反馈弹窗
    async hideFeedbackRecPopup() {
      const { goodsId } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex >= 0) {
        // 再通过 index 拿到对应的 productListItem 实例
        const listItemRef = this.getProductListItemInstance(goodsIndex)
        let recommendPopupRef = listItemRef?.$refs?.CardRecommend
        if(recommendPopupRef) {
          recommendPopupRef.showCardRecommend = false
          // recommendPopupRef.showNewRecPopType = ''
        }
        // 清除值
        // recommendPopupRef.showNewRecPopType = ''
        // listItemRef.recommendPopupGood = null
      }
    },
    handleFeedbackRecInfo ({ active, clickDetails, goodsId, addBagStatus, addWishStatus, isRecommend }) {
      if (!isRecommend) return

      if (!active) {
        // clickDetails 点击details跳去商详，会关闭弹窗。此种情况，不取弹窗里面的操作状态（加车和收藏）
        if (!clickDetails && (this.catInfo.showFeedbackRec != 0 || !this.catInfo.showFeedbackRec) && !['feedbackRecPage', 'RecommendDrawer'].includes(this.useFrom)) {
          setFeedbackRecInSession({ goodsId, addBagStatus, addWishStatus })
          this.showFeedbackRecPopup()
        }
      } else {
        this.hideFeedbackRecPopup()
      }
    }
  }
}
